// Regex adapted from VariableName validator in validators.py
// Outer capture group: variable name with curlies, inner capture group: variable name only
const VARIABLE_NAME_REGEX = /(\{([a-z]{1}[_0-9a-z]{0,63})\})/g
const VARIABLE_TAG_REGEX = /(\[var:[0-9a-z-]{36}\|([0-9a-zA-Z\s]+)])/g

/**
 * Join strings to a path
 * @param entity
 * @param id
 * @param paths
 * @returns {string}
 */
export const createApiUrl = (entity, id = '', ...paths) => {
  const path = ['', entity]

  if (id) {
    path.push(id)
  }

  if (paths) {
    paths.filter(Boolean).forEach((item) => path.push(item))
  }

  return path.join('/').replace('//', '/')
}

/**
 * @param url
 * @returns {Number|undefined}
 */
export const getVimeoIdFromUrl = (url = '') => {
  const reg =
    '(?:http|https)?:\\/\\/(?:www\\.)?vimeo.com\\/(?:channels\\/(?:\\w+\\/)?|groups\\/(?:[^\\/]*)\\/videos\\/|)(\\d+)(?:|\\/\\?)'
  const results = url.match(reg)

  return results ? results[1] : undefined
}

/**
 * Split email address to return first part
 * @param {string} email
 * @returns {string}
 */
export const toEmailId = (email) => {
  if (typeof email !== 'string') {
    return ''
  }

  const index = email.indexOf('@')

  return index > 0 ? email.substring(0, index) : email
}

/**
 * Object to class list
 * @param {Object} obj
 * @returns {string}
 */
export const toClassList = (obj = {}) =>
  Object.keys(obj)
    .filter((value) => obj[value])
    .join(' ')

/**
 * @param {string} string
 * @returns {string}
 */
export const toLower = (string = '') => {
  if (typeof string !== 'string') {
    return ''
  }

  return string.toLowerCase()
}

/**
 * @param {string} string
 * @returns {string}
 */
export const toUpper = (string = '') => {
  if (typeof string !== 'string') {
    return ''
  }

  return string.toUpperCase()
}

/**
 * Escape string replace
 * @param {string} string
 * @param {string} char
 * @returns {string}
 */
export const spaceTo = (string = '', char = '') => {
  if (typeof string !== 'string') {
    return ''
  }

  return string.replace(/[ ]/g, char)
}

/**
 * @param {string} string
 * @returns {string}
 */
export const toCamel = (string = '') =>
  string.replace(/([-_][a-z])/gi, ($1) => {
    return $1.toUpperCase().replace('-', '').replace('_', '')
  })

/**
 * @param {string} string
 * @returns {string}
 */
export const dashToSnakeCase = (string = '') => string.replace(/-/g, '_')

/**
 * camelCaseToDash('userId') => "user-id"
 * camelCaseToDash('waitAMoment') => "wait-a-moment"
 * camelCaseToDash('TurboPascal') => "turbo-pascal"
 */
export const camelCaseToDash = (string = '') =>
  string.replace(/([a-zA-Z])(?=[A-Z])/g, '$1-').toLowerCase()

/**
 * @param {string} string
 * @param {Boolean} glue
 * @returns {string|*}
 */
export const getInitials = (string = '', glue = true) => {
  if (string.length < 2) {
    throw new Error('Can not get initials from empty string')
  }

  let initials = ''

  const cleanString = string.replace(/[.]/g, '')
  const matchedString = cleanString.match(/\b\w/g)

  if (matchedString) {
    initials = matchedString.slice(0, 2)
  } else {
    initials = cleanString.split('').slice(0, 2)
  }

  if (initials.length !== 2) {
    initials = string.substring(0, 2).split('')
  }

  if (glue) {
    return initials.join('').toUpperCase()
  }

  return initials.map((item) => item.toUpperCase())
}

/**
 * Get first part of language from navigator object
 * @returns {String}
 */
export function getBrowserLocaleCode() {
  const locale =
    (navigator.languages && navigator.languages[0]) || navigator.language

  // Only keep the language code, not the region as we do not support regions (yet)
  if (locale.includes('-')) {
    return locale.substring(0, locale.indexOf('-'))
  }

  return locale
}

export const isObject = (obj) => obj && typeof obj === 'object'
export const isString = (string) => string && typeof string === 'string'

export const isPlainObject = (object) =>
  Object.prototype.toString.call(object) === '[object Object]'

export const mapWith = (data, obj) => {
  if (!isObject(obj)) {
    return data
  }

  if (Array.isArray(data)) {
    return data.map((item) => ({ ...obj, ...item }))
  }

  if (isObject(data)) {
    return { ...obj, ...data }
  }

  return data
}

export const isNumber = (n) => typeof n === 'number'

export const isNumeric = (n) => !isNaN(n)

export const countSign = (text, sign) => {
  return text.split(sign).length - 1
}

export const stripHtml = (html) => {
  const tmp = document.createElement('DIV')

  tmp.innerHTML = html

  return tmp.textContent || ''
}

export const toRadian = (val) => {
  if (!isNumber(val) || !isNumeric(val)) {
    return 0
  }

  return (val / 180) * Math.PI
}

export const toNumber = (val) => {
  if (!isNumber(val) || !isNumeric(val)) {
    return 0
  }

  return Number(val) || 0
}

const matrixTimes = ([[a, b], [c, d]], [x, y]) => [a * x + b * y, c * x + d * y]

const rotateMatrix = (x) => [
  [Math.cos(x), -Math.sin(x)],
  [Math.sin(x), Math.cos(x)],
]

const matrixAdd = ([a1, a2], [b1, b2]) => [a1 + b1, a2 + b2]

// url: SVG Circle Arc http://xahlee.info/js/svg_circle_arc.html
// Version 2019-06-19
/**
 * SVG path element that represent an ellipse.
 * @param {number} cx - start point of the x-axis
 * @param {number} cy - start point of the y-axis
 * @param {number} rx - radius in the x-axis
 * @param {number} ry - radius in the y-axis
 * @param {number} sweepStart - start angle, in radian.
 * @param {number} sweepDelta - angle to sweep, in radian. positive.
 * @param {number} rotation - rotation on the whole, in radian
 * @returns {string}
 */
export const generateArcPath = (
  [cx, cy],
  [rx, ry],
  [sweepStart, sweepDelta],
  rotation = 0,
) => {
  const sweepStartInRad = toRadian(sweepStart)
  const sweepDeltaInRad = toRadian(sweepDelta) % (2 * Math.PI)
  const rotatedMatrix = rotateMatrix(rotation)

  const [startX, startY] = matrixAdd(
    matrixTimes(rotatedMatrix, [
      rx * Math.cos(sweepStartInRad),
      ry * Math.sin(sweepStartInRad),
    ]),
    [cx, cy],
  )
  const sweeps = sweepStartInRad + sweepDeltaInRad
  const [endX, endY] = matrixAdd(
    matrixTimes(rotatedMatrix, [rx * Math.cos(sweeps), ry * Math.sin(sweeps)]),
    [cx, cy],
  )

  const largeArcFlag = sweepDeltaInRad > Math.PI ? 1 : 0
  const sweepFlag = sweepDeltaInRad > 0 ? 1 : 0

  return [
    'M',
    startX,
    startY,
    'A',
    rx,
    ry,
    (rotation / (2 * Math.PI)) * 360,
    largeArcFlag,
    sweepFlag,
    endX,
    endY,
  ].join(' ')
}

/**
 * Calculates sum
 * @param {number[]} nums - numbers to sum
 * @returns {number|0}
 */
export const sum = (nums) => {
  if (!Array.isArray(nums)) {
    return 0
  }

  return nums.reduce((total, n) => total + n, 0)
}

/**
 * Convert a JSON alike Object to FormData
 * @param {Object} obj - object to convert
 * @param {FormData} formData - FormData object
 * @param {string} parentKey - parent key
 * @returns {FormData}
 */
export function objectToFormData(
  obj,
  formData = new FormData(),
  parentKey = '',
) {
  for (const key in obj) {
    const value = obj[key]
    const formKey = parentKey ? `${parentKey}[${key}]` : key

    if (value instanceof Date) {
      formData.append(formKey, value.toISOString())
    } else if (value instanceof File || value instanceof Blob) {
      formData.append(formKey, value)
    } else if (typeof value === 'object' && value !== null) {
      objectToFormData(value, formData, formKey)
    } else {
      formData.append(formKey, value === null ? '' : value)
    }
  }

  return formData
}

// https://stackoverflow.com/questions/5731863/mapping-a-numeric-range-onto-another
// https://www.arduino.cc/reference/en/language/functions/math/map/
export const mapNumericScaleToAnother = (
  value,
  inputScaleMin,
  inputScaleMax,
  outputScaleMin,
  outputScaleMax,
) => {
  // Formula: f(x) = (x - input_start) / (input_end - input_start) * (output_end - output_start) + output_start
  return (
    ((value - inputScaleMin) * (outputScaleMax - outputScaleMin)) /
      (inputScaleMax - inputScaleMin) +
    outputScaleMin
  )
}

export const getNPSConversionRate = (respondents, participants) => {
  if (participants === 0) {
    return 0
  }

  return Math.round((respondents / participants) * 100)
}

export const removeWhitespace = (value) => {
  if (typeof value !== 'string') {
    return ''
  }

  return value.replace(/\s/g, '')
}

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/padStart
export const leftPadNumber = (num, targetLength, padding = '0') => {
  return num.toString().padStart(targetLength, padding)
}

export const capitalise = (value) => {
  if (typeof value !== 'string') {
    return value
  }

  return value.charAt(0).toUpperCase() + value.slice(1)
}

/**
 * Sets the query parameters manually using history.replaceState
 * @param {object} params - query parameter object
 */
export const setQueryParameters = (params) => {
  const urlParams = new URLSearchParams(params).toString()

  const { origin, pathname } = window.location
  const url = `${origin}${pathname}${urlParams !== '' ? '?' : ''}${urlParams}`

  window.history.replaceState('', '', url)
}

export const markVariablesInHtml = (
  html,
  prefix = '<span class="tag">',
  suffix = '</span>',
) => {
  if (!html) {
    return ''
  }

  // Surround the capture groups with prefix and suffix.
  return html
    .replace(VARIABLE_NAME_REGEX, `${prefix}$2${suffix}`)
    .replace(VARIABLE_TAG_REGEX, `${prefix}$2${suffix}`)
}

export const debounce = (fn, ms = 0) => {
  let timeoutId

  return function (...args) {
    clearTimeout(timeoutId)
    timeoutId = setTimeout(() => fn.apply(this, args), ms)
  }
}

export const toFullName = ({ firstName, lastName }) =>
  [firstName, lastName].filter(Boolean).join(' ')

/**
 * Removes <p></p> tags from string and trims all the whitespaces
 * @param {string} text
 * @returns {string}
 */
export const removeParagraphTag = (text) => {
  return text.replace(/<p>|<\/p>/gi, '').trim()
}

export const getModuleKeyForLocalStorage = (module) => {
  if (module === 'ai') {
    return 'ai-app-version'
  }

  if (module === 'robot') {
    return 'robot-app-version'
  }

  return 'chatbot-app-version'
}

export const convertTimestampToDate = (timestamp) => {
  const date = new Date(timestamp)
  const day = String(date.getDate()).padStart(2, '0')
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const year = date.getFullYear()

  return `${day}/${month}/${year}`
}

export const formatFileSize = (bytes) => {
  if (bytes < 1024) {
    return bytes + ' bytes'
  } else if (bytes < 1024 * 1024) {
    return (bytes / 1024).toFixed(2) + ' KiB'
  } else if (bytes < 1024 * 1024 * 1024) {
    return (bytes / (1024 * 1024)).toFixed(2) + ' MiB'
  } else {
    return (bytes / (1024 * 1024 * 1024)).toFixed(2) + ' GiB'
  }
}
